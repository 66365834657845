@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@font-face {
  font-family: 'TATAAIARegularPrint';
  src: url('/src/assets/fonts/TATA AIA Regular Print.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TATAAIAPrint';
  src: url('/src/assets/fonts/TATA AIA Print.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd';
  src: url('/src/assets/fonts/HelveticaNeueLTStd-Lt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
} 

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide the arrows for WebKit browsers */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Set appearance to textfield to remove default styling */
input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}
