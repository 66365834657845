.swipeable-cards {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  height: 500px;
}

.swipe {
  position: absolute;
}

.card {
  position: relative;
  width: 300px;
  max-width: 85vw;
  height: 400px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0px 18px 53px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.card h3 {
  margin-bottom: 10px;
  color: #fff;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.content.show {
  max-height: 40vh; /* Adjust according to your content's height */
}

.swiper-backface-hidden .swiper-slide{
      backface-visibility: unset !important;
}
 
/* .swiper-slide img{
  border-radius: 20px;
} */

.css-doq0dk .css-1fzpoyk:nth-child(1) {
  opacity: 0.6 !important;
  transform: translateY(-50%) translateX(-25%) scale(1) !important;
  /* scale: 2; */
}

.css-doq0dk .css-1fzpoyk:nth-child(3) {
  opacity: 0.6 !important;
  transform: translateY(-50%) translateX(-75%) scale(1) !important;
}

/* .css-doq0dk .css-1fzpoyk:nth-child(even) {
  opacity: 0.7;
  transform: translateY(-50%) translateX(-50%) scale(1) !important;
} */

.css-doq0dk {
    position: relative;
    width: 80% !important;
    height: 100%;
    margin: auto;
}

.card-container {
    perspective: 1000px; /* Add perspective for 3D effect */
    overflow: visible;
    /* justify-content: space-between; */
    height: auto;
    gap: 2rem; /* Increase the gap between cards */
    /* overflow: visible; Ensure shadows are not clipped */
}
.second .css-doq0dk{
  height: 75% !important;
}

.first .css-doq0dk{
  height: 100% !important;
}

.animated-carousel-dots {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated-carousel-dot {
  background: #aaa;
  border: none;
  height: 10px;
  width: 10px;
  margin: 3px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: background 0.2s ease-out;
}

.animated-carousel-dot.active {
  background: #555;
}

/* .listings::-webkit-scrollbar-track {
  background: #f8f8f8;
  -webkit-box-shadow: 0px 0px 0px #f8f8f8;
  border: none;
}
.listings::-webkit-scrollbar-thumb {
  width:1px !important;
  background: #59B3C6;
}
.listings::-webkit-scrollbar-thumb {
  border-radius: 10px;
  scrollbar-width: thin;
}
.listings::-webkit-scrollbar {
  width: 4px !important;
} */

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Change width here */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change background color here */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Change thumb color here */
  border-radius: 6px; /* Optional: Round the corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change thumb hover color here */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #c2c2c2 #f1f1f1; /* thumb and track color */
}

.common .carousel-container .slick-track,.common .slick-list{
  border-radius: 0px !important;
}

.height-adjust{
  height:70px;
}

.solution-type .card{
  box-shadow: 0px 5px 10px #c3d9e3 !important;
}

.gradient-shadow {
  box-shadow: -5px 5px 5px 2px #b9d7e8, 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.gradient-shadow-lg {
  box-shadow: -8px 7px 5px 2px #d1e4ef, 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.animated-carousel-dot.active {
    background: #D62049;
}

.ticker-container {
  overflow: hidden;
  background-color: #0073BB;
  color: #fff;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
  margin-top: 15px;
  padding: 2px 0;
  position: relative;
}

.ticker-text {
  display: inline-block;
  padding-left: 100%;
  animation: ticker 30s linear infinite;
}

.ticker-text span {
  display: inline-block;
  padding-right: 5%; /* Space between the two copies */
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}



/* .country-customize-expert .react-tel-input .form-control {
    height: 48px !important;
    width: 100% !important;
}

.country-customize-expert .react-tel-input .flag-dropdown.invalid-number {
    border-color: #d1d5db !important;
}

.country-customize .react-tel-input .form-control {
    height: 37px !important;
    width: 100% !important;
    border-radius:5px !important;
}

.country-customize .react-tel-input .form-control::placeholder {
  font-size: 12px;
}

 .country-customize .react-tel-input .flag-dropdown.invalid-number {
    border-color: #d1d5db !important;
} */

/* .font-custom .react-tel-input .form-control {
    height: 32px !important;
    width: 100% !important;
    font-size: 12px;
}

.font-custom .react-tel-input .form-control::placeholder {
  font-size: 11px;
}

.delay-custom .react-tel-input .form-control {
    height: 36px !important;
    width: 100% !important;
    font-size: 12px;
}

.delay-custom .react-tel-input .form-control::placeholder {
  font-size: 11px;
} */

.react-share__ShareButton{
  margin: auto;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .height-adjust{
    height:70px;
  }
}