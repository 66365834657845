.main-div-404{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    bottom: 0;
    background-color: #171c35;
}

#wrapper{
    background-color: #171c35;
}
.brick {
    position: absolute;
    top: 135px;
    left: -20px;
    width: 435px;
    height: 210px;
    background-image: url("../../assets/pagenotfound/wall-break-img.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.number {
    position: relative;
    z-index: 100;
    text-align: right;
    margin-top: 15px;
}

.number .four {
    width: 230px;
    height: 292px;
    display: inline-block;
    background-image: url("../../assets/pagenotfound/4-img.png");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 19;
    position: relative;
}

.number .zero {
    width: 230px;
    height: 292px;
    display: inline-block;
    background-image: url("../../assets/pagenotfound/0-img.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 20px;
    position: relative;
    z-index: 18;
    -moz-animation: 4s ease 0s normal none infinite zero;
    -moz-transform-origin: center top;
    -webkit-animation: zero 4s infinite ease-in-out;
    -webkit-transform-origin: top;
    -o-animation: 4s ease 0s normal none infinite zero;
    -o-transform-origin: center top;
    -o-animation: zero 4s infinite ease-in-out;
    -o-transform-origin: top;
    -ms-animation: 4s ease 0s normal none infinite zero;
    -ms-transform-origin: center top;
    -ms-animation: zero 4s infinite ease-in-out;
    -ms-transform-origin: top;
    animation: 4s ease 0s normal none infinite zero;
    transform-origin: center top;
    animation: zero 4s infinite ease-in-out;
    transform-origin: top;
}

.number .zero .nail {
    width: 30px;
    height: 50px;
    top: 6px;
    left: 115px;
    background-image: url("../../assets/pagenotfound/pin-img.png");
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
}

.info {
    margin-left: 460px;
    position: relative;
    z-index: 999;
}

.info p {
    font-size: 18px;
    color: #fff;
}

.h1-head {
    color: #fff;
}

#wrapper .btn {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    border: 0 solid;
    border-bottom: 2px solid;
    border-color: #ffffff;
    padding: 10px 41px;
    border-radius: 5px;
    background: none;
    display: inline-block;
    margin: 10px 0;
    background-color: #f6971a;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

.worker {
    position: absolute;
    left: 0;
    top: 4px;
    width: 300px;
    height: 371px;
    background-size: 100%;
    background-image: url("../../assets/pagenotfound/404-images01.png");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
}

#footer {
    width: 100%;
    text-align: center;
    height: 375px;
    margin-top: -375px;
}

.tools {
    position: absolute;
    right: 0;
    top: 176px;
    width: 730px;
    height: 199px;
    background-size: 100%;
    background-image: url("../../assets/pagenotfound/tools-img.png");
    background-repeat: no-repeat;
    background-position: center;
}

#wrapper {
    min-height: 100%;
    height: 100%;
    width: 100%;
}

.container {
    position: relative;
}

.logo-link {
    display: block;
    padding-top: 20px;
}
@media (min-width: 768px){
.container-404 {
    width: 750px;}
}
@media (min-width: 992px){
.container-404 {
    width: 970px;}
} 
@media (min-width: 1200px){
.container-404 {
    width: 1170px;}
}

