/* .carousel-container {
  width: 80%;
  margin: 0 auto;
} */

.video-slide video {
  width: 100%;
  height: auto;
}

.custom-dots li {
  width: 70px !important; /* Adjust the size as needed */
  height: 70px !important;
}

.custom-dots li img {
  width: 100%;
  height: auto;
}

.carousel-container .slick-dots{
    margin: 0 0 25px;
    position: relative
}

.client .slick-dots{
    bottom: 0px;
}

.custom-dots li.slick-active img {
    border: 3px solid #cbc9c9;
    opacity: 1;
}

.custom-dots li .away::before {
    content: "";
    position: absolute;
    top: 0px;
    left: -35px;
    width: 200%;
    border-radius: 15px;
    height: 100%;
    z-index: 8;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.custom-dots li.slick-active .away::before {
    display: none;
}

.custom-dots li.slick-active svg {
  display: block !important;
  color: #cbc9c9;
  font-size: 12px;
}

.custom-dots li.slick-active .count {
  display: none;
}

.custom-dots li .count {
  z-index: 10;
}

.custom-dots li svg {
  display: none;
}

.custom-dots li img {
    border: 3px solid #ffffff;
    opacity: 0.7;
}

.carousel-container .slick-track, .slick-list {
  border-radius: 20px !important
}

.client .slick-track,.client .slick-list {
  border-radius: 0px !important
}

.carousel-container .slick-list{
  height: 100%
}

.video-slide{
  background-color: gray;
   background-image: linear-gradient(gray, #f3f4f6);
}

.video-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: auto;
  display: block;
}

.play-pause-btn {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 16px;
}

.play-pause-btn:hover {
  background-color: rgba(255, 255, 255, 0.5);
}


.client .slick-slide {
  padding: 0 10px; /* Adjust the padding to your desired gap */
}

.client .slick-list {
  margin: 0 -10px; /* Negative margin to compensate for the slide padding */
}

/* .best-selling .slick-slide {
    padding: 0px 50px;
} */

.primary-carousel .slick-slide.slick-active{
  opacity: 0.5;
  transform: rotate(-10deg) translateY(5%) scale(0.9);
}

.primary-carousel .slick-slide.slick-active.slick-center{
  opacity: 1;
  transform: scale(1);
}

.primary-carousel .slick-slide {
  transition: transform 0.3s ease;
}

.primary-carousel .slick-slide.slick-current.slick-active + .slick-active {
  transform: rotateZ(-10deg) translateY(5%) scale(0.9);
}

.primary-carousel .slick-slide.slick-active + .slick-active + .slick-active {
  transform: rotateZ(10deg) translateY(5%) scale(0.9);
}

.primary-carousel .slick-slide{
  transform: scale(0.9);
}

.one-card-carousel .slick-slide.slick-active{
  opacity: 1;
  transform: rotate(0deg) translateY(5%) scale(1);
}

.one-card-carousel .slick-slide.slick-active.slick-center{
  opacity: 1;
  transform: scale(1);
}

.one-card-carousel .slick-slide {
  transition: none;
}

.one-card-carousel .slick-slide.slick-current.slick-active + .slick-active {
  transform: rotateZ(0deg) translateY(5%) scale(1);
}

.one-card-carousel .slick-slide.slick-active + .slick-active + .slick-active {
  transform: rotateZ(0deg) translateY(5%) scale(1);
}

.one-card-carousel .slick-slide{
  transform: scale(1);
}

.showSwipe{
    display: none !important;
}

.secondary-carousel .slick-slide {
  transition: transform 0.3s ease;
}

.secondary-carousel .slick-slide.slick-active {
  opacity: 0.5;
  transform: rotate(-10deg) translateY(5%) scale(0.9);
}

.secondary-carousel .slick-slide.slick-center {
  transform: rotateZ(-10deg) translateY(5%) scale(0.9);
  
}

.secondary-carousel .slick-slide.slick-current.slick-active + .slick-active {
  transform: scale(1);
  opacity: 1;
}

.secondary-carousel .slick-slide.slick-active + .slick-active + .slick-active {
  transform: rotateZ(10deg) translateY(5%) scale(0.9);
}

.secondary-carousel .slick-slide {
  transform: scale(0.9);
}

.top-carousel .slick-prev{
    background: transparent;
    height: 100%;
    width: 35%;
    cursor: auto;
    z-index: 9 !important;
}

.top-carousel .slick-prev::before{
  display: none;
}

.top-carousel .slick-next::before{
  display: none;
}

.video-carousel .slick-prev::before{
  display: none;
}

.video-carousel .slick-next::before{
  display: none;
}

.top-carousel .slick-next{
  background: transparent;
  height: 100%;
  cursor: auto;
  width: 35%;
}

.slick-prev:before, .slick-next:before{
  color: rgb(208, 208, 208);
  font-size: 25px !important;
}

@media (max-width: 768px) {
  .slick-prev{
    display: none !important;
  }
  .slick-next{
    display: none !important;
  }
  .showSwipe{
    display: block !important;
  }
  .custom-dots li {
    width: 60px !important; /* Adjust the size as needed */
    height: 50px;
  }
  .carousel-container .slick-list{
    height: fit-content
  }
  .custom-dots li .away::before {
    left: -30px;
    border-radius: 10px;
  }
  .best-selling .slick-slide {
    padding: 0px 10px;
  }

  .primary-carousel .slick-slide.slick-active.slick-center{
    opacity: 1;
    transform: scale(1.5);
  }
}
