.country-customize input{
    display: block; 
    padding-top: 0.375rem;
    padding-bottom: 0.375rem; 
    border-radius: 0.375rem; 
    border: 1px solid #d1d5db !important;
    width: 100%; 
    height: 36px;
    color: #111827; 
    font-size: 12px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
}

.country-customize input:focus{
    --tw-ring-shadow: 2px 0 0 0 2px #4b5563 !important;
}

.country-customize input::placeholder {
    color: #a8a8a8; /* Change to your desired color */
    font-size: 15px;
}

.country-customize input {
    font-size: 15px;
}

.country-customize-expert input {
    display: block; 
    padding-top: 0.375rem;
    padding-bottom: 0.375rem; 
    border-radius: 0.375rem; 
    border-width: 0; 
    border: 1px solid #d1d5db !important;
    width: 100%; 
    height: 3rem; 
    color: #111827; 
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
}

.delay-form input{
    font-size: 13px !important;
}

.country-customize-expert input:focus{
    --tw-ring-shadow: 2px 0 0 0 2px #4b5563 !important;
}

.font-custom input{
    height: 32px !important;
}

.PhoneInputCountry{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.PhoneInputInput{
    padding-left:50px
}

.PhoneInputCountryIcon{
    color: #0073BB
}

.PhoneInputInput::placeholder{
    font-size: 12px !important
}

/* .primary-carousel li.slick-active button:before {
    opacity: .75;
    color: #D62049;
} */

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #D62049 !important;
}

.gradient-text {
  background: linear-gradient(#0073BB, #D62049);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-cover {
  background: linear-gradient(#0073BB, #D62049);
}

.font-card-custom input{
  font-size: 12px;
}


.gradient-shadow-header {
  box-shadow: -1px 1px 5px 2px #b9d7e8, 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

@media (max-width: 768px) {
    .PhoneInputInput::placeholder{
        font-size: 16px !important
    }
    .font-card-custom input{
        font-size: 12px;
    }
}